<template>
  <p-badge v-if="badge"
           :severity="color"
           :class="[{ 'border': color === 'light', 'right-0': !relative }, customBg]"
           size="small"
           pt:root:style:borderRadius="1rem"
           pt:root:style:fontSize="0.625rem"
           class="position-absolute top-0 font-weight-bold"><slot></slot></p-badge>
  <span v-else
        :class="color"
        class="round xs position-absolute top-0 font-weight-bold"
        style="right:1px; font-size:10px;"><slot></slot></span>
</template>

<script>
export default {
  name: 'notificationBadge',
  props: {
    color: {
      type: String,
      default: 'danger'
    },
    badge: {
      type: Boolean,
      default: true
    },
    relative: {
      type: Boolean,
      default: false
    }
  },
   computed: {
     customBg () {
       const colors = ['account-bg', 'campaign-bg', 'ad-group-bg', 'budget-bg']

       if (colors.includes(this.color)) {
         return this.color
       }

       return null
    }
  }
}
</script>

<style lang="scss" scoped>
.account-bg {
  background-color: $color-key-account;
}

.campaign-bg {
  background-color: $color-key-campaign;
}

.ad-group-bg {
  background-color: $color-key-adgroup;
}

.budget-bg {
  background-color: $color-key-budget;
}
</style>
